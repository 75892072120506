.s1vzx7jy{position:fixed;top:0;left:0;right:0;height:1px;background:rgba(240,147,43,1);z-index:9999999;}
.laosm5p{position:fixed;top:0;left:0;right:0;height:1px;background:rgba(126,214,223,1);z-index:9999999;}

.spiw0w4{width:100%;display:flex;align-items:center;justify-content:space-between;padding:var(--gh-size-xxxs);border-radius:var(--gh-sys-radius-default);background:var(--gh-sys-surface-default-background);position:relative;}.spiw0w4 button{padding:0 1rem;}

.s156wndi{all:unset;display:flex;align-items:center;justify-content:flex-start;padding:0;cursor:pointer;transform:translateX(-9px);}

.sjiuz8n{display:flex;flex-direction:column;align-items:center;position:fixed;top:0;left:0;right:0;width:100%;background:var(--gh-sys-palette-neutral-light-100);z-index:var(--gh-sys-zIndex-topNav);border-bottom:1px solid var(--gh-sys-color-border-darker);}.sjiuz8n[data-no-border='true']{border-bottom:0;}@media (min-width:768px){.sjiuz8n{left:var(--gh-sys-dimensions-sidebarNav-width);width:calc(100% - var(--gh-sys-dimensions-sidebarNav-width));border-bottom:1px solid var(--gh-sys-color-border-darker);}.sjiuz8n[data-no-sidebar='true']{left:0;width:100%;}}
.r1twy7j5{display:grid;grid-template-areas:'left center right';grid-template-columns:1fr auto 1fr;height:48px;align-content:center;padding:0;width:100%;max-width:var(--gh-sys-dimensions-maxWidthDesktop);}.r1twy7j5[data-no-grid='true']{grid-template-columns:unset;}.r1twy7j5[data-with-padding='true']{padding:0 var(--gh-size-s);}@media (min-width:768px){.r1twy7j5#subnav-tabs{display:none;}}
.cj4qh1c{display:flex;align-items:center;}.cj4qh1c[data-grid-area='left']{grid-area:left;}.cj4qh1c[data-grid-area='center']{grid-area:center;display:flex;justify-content:center;}.cj4qh1c[data-grid-area='right']{grid-area:right;display:flex;justify-content:flex-end;}
.s4f2dd2{display:flex;gap:var(--gh-size-xs);}
.sf7mrhg{width:var(--gh-size-l);height:var(--gh-size-l);display:flex;align-items:center;justify-content:center;border:none;background:transparent;border-radius:20px;color:var(--gh-sys-palette-neutral-dark-200);}.sf7mrhg:active{opacity:0.5;}

.cemngfb{height:100%;width:100%;display:flex;align-items:center;justify-content:center;}

.sw2w7tn{position:fixed;bottom:0;left:0;right:0;width:100%;height:var(--gh-sys-dimensions-tabNavHeight);border-top:1px solid var(--gh-sys-color-border-default);background:var(--gh-sys-palette-neutral-light-100);display:flex;justify-content:center;z-index:var(--gh-sys-zIndex-tabNav);}@media (max-width:767px){.sw2w7tn[data-hidden='true']{display:none;}}@media (min-width:768px){.sw2w7tn{right:unset;width:var(--gh-sys-dimensions-sidebarNav-width);height:100%;flex-direction:column;justify-content:flex-start;align-items:flex-start;padding:0 var(--gh-size-m);border-top:none;border-right:1px solid var(--gh-sys-color-border-default);}}
.l149p7yq{width:100%;height:var(--gh-sys-dimensions-topNavHeight-desktop);display:none;}@media (min-width:768px){.l149p7yq{display:flex;align-items:center;}}
.d1bxatiz{width:7.5rem;height:1.5rem;position:relative;}
.sjr616b{width:100%;height:100%;}@media (min-width:768px){.sjr616b{display:flex;flex-direction:column;justify-content:space-between;padding-bottom:var(--gh-size-m);}}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_e66fe9';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_e66fe9';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_e66fe9';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_e66fe9';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_e66fe9';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_e66fe9';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_e66fe9';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_e66fe9';src: local("Arial");ascent-override: 90.20%;descent-override: 22.48%;line-gap-override: 0.00%;size-adjust: 107.40%
}.__className_e66fe9 {font-family: '__Inter_e66fe9', '__Inter_Fallback_e66fe9';font-style: normal
}

